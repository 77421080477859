import React, { Fragment } from 'react';
// import Layout,{DefaultHelmet}  from '../components/layout';
import {NoHeadFootLayout} from '../components/layout';
import SignInForm, {
  SignInGoogle,
  SignInFacebook,
  SignInTwitter,
} from '../components/SignIn';

const SignInPage = () => (
  <Fragment>
    {/* <div className="page-content pb-0">  */}
        <div className="display-flex flex-column justify-content-center align-items-center align-self-center" style={{display: 'flex',height: '100vh'}}>
            <div className="card-overlay bg-black opacity-100"></div>
            {/* <div className="card-overlay-infinite preload-img" data-src="images/pictures/_bg-infinite.jpg"></div> */}
            <div className="pl-5 pr-5" style={{ zIndex: '2', width:'100%',maxWidth: '500px'}}>
                {/* <div className=""> */}
                    <h1 className="color-white text-center text-uppercase font-900 fa-4x">LOGIN</h1>
                    <p className="color-highlight text-center font-12">
                        ViP IoT Plus
                    </p>
                    <SignInForm />
                    <div className="divider mt-3"></div>
                    <SignInGoogle />
                    <SignInFacebook />
                    <SignInTwitter />
                {/* </div> */}

            </div>
        </div>
      {/* </div> */}
  </Fragment>
);

export default () => (
  <NoHeadFootLayout>
    <SignInPage/>
  </NoHeadFootLayout>
);

import React, { Component } from 'react';
import { navigate } from 'gatsby';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (             
      <form onSubmit={this.onSubmit}>
        <div className="input-style input-light has-icon input-style-1 input-required">
            <i className="input-icon fa fa-user font-11"></i>
            <span>Username</span>
            <em>(required)</em>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
        </div> 
        <div className="input-style input-light has-icon input-style-1 input-required mb-5">
            <i className="input-icon fa fa-lock font-11"></i>
            <span>Password</span>
            <em>(required)</em>
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
        </div>    
        <div className="d-flex mt-n4 mb-4">
          
              <SignUpLink />
              <PasswordForgetLink />
            {/* <div className="w-50 font-11 pb-2 color-white opacity-60 text-left"><a href="#" className="color-white">Create Account</a></div>
            <div className="w-50 font-11 pb-2 color-white opacity-60 text-right"><a href="#" className="color-white">Forgot Credentials</a></div> */}
        </div>

        <a disabled={isInvalid} type="submit" className="back-button  btn-full btn btn-m shadow-large rounded-s text-uppercase font-900 bg-highlight" style={{width: '100%'}}>LOGIN</a>
        {error && <p>{error.message}</p>}
      </form>      


      // <form onSubmit={this.onSubmit}>
      //   <input
      //     name="email"
      //     value={email}
      //     onChange={this.onChange}
      //     type="text"
      //     placeholder="Email Address"
      //   />
      //   <input
      //     name="password"
      //     value={password}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="Password"
      //   />
      //   <button disabled={isInvalid} type="submit">
      //     Sign In
      //   </button>

      // </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        
        this.setState({ error });
      });

    event.preventDefault();
    return false;
  };

  render() {
    const { error } = this.state;

    return (
      <a href="#"  onClick={this.onSubmit} className="btn-full btn btn-icon btn-m shadow-l rounded-s bg-google text-uppercase font-900 text-left mt-2 ">
        {/* <i className="fab fa-google text-center">  */}
        <span className="google-icon text-center"></span>
        {/* </i> */}
       
        Login with Google
      {error && <p>{error.message}</p>}
      </a>
      // <form onSubmit={this.onSubmit}>
      //   <button type="submit">Sign In with Google</button>

      //   {error && <p>{error.message}</p>}
      // </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (

      <a href="#" className="btn-full btn btn-icon btn-m shadow-l rounded-s bg-facebook text-uppercase font-900 text-left mt-2"><i className="fab fa-facebook-f text-center"></i>Login with Facebook</a>
      // <form onSubmit={this.onSubmit}>
      //   <button type="submit">Sign In with Facebook</button>
      //   {error && <p>{error.message}</p>}
      // </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <a href="#" className="btn-full btn btn-icon btn-m shadow-l rounded-s bg-twitter text-uppercase font-900 text-left mt-2 "><i className="fab fa-twitter text-center"></i>Login with Twitter</a>
      // <form onSubmit={this.onSubmit}>
      //   <button type="submit">Sign In with Twitter</button>

      //   {error && <p>{error.message}</p>}
      // </form>
    );
  }
}

const SignInForm = withFirebase(SignInFormBase);

const SignInGoogle = withFirebase(SignInGoogleBase);

const SignInFacebook = withFirebase(SignInFacebookBase);

const SignInTwitter = withFirebase(SignInTwitterBase);

export default SignInForm;

export { SignInGoogle, SignInFacebook, SignInTwitter };
